<template>
  <div class="c-app flex-row align-items-center bg-blue">
    <CContainer>
      <CRow id="mainRow">
        <CCol lg="6">
          <h1 class="text-yellow80">Scan</h1>
          <div id="my_camera" class="center"></div>
          <div id="modelStatus" class="text-center" style="margin-top: 200px">
            <div class="spinner-border text-success" role="status">
              <span class="sr-only"></span>
            </div>
            Loading...
          </div>
          <div class="col-lg-6 center text-center" id="detectionResult"></div>
        </CCol>

        <CCol id="secondRow" class="text-center">
          <div
            class="btn-group btn-group-lg"
            role="group"
            aria-label="Basic example"
          >
            <button
              id="btnCheckIn"
              type="button"
              class="btn btn-info"
              onclick="sendDataTocheckIn()"
            >
              Check In
            </button>
            <button
              id="btnCheckOut"
              type="button"
              class="btn btn-light"
              onclick="sendDataTocheckOut()"
            >
              Check Out
            </button>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import ScriptFace from "../../ScriptFace";
import * as webcamAPI from "../../webcamjs/webcam.min.js";
import * as faceAPI from "../../face-api.min.js";
import asyncFunc from "../../asyncFunc.js";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      darkModal: false,
      person: null,
      school: null,
      image: null,
      canvas: null,
      detectionContainer: null,
      targetPicHost: null,
      targetCheckInAPI: null,
    };
  },
  beforeCreate() {},
  async created() {},
  mounted() {
    const mainContent = document.getElementById("secondRow");
    var container = document.createElement("div");
    container.style.position = "relative";
    //document.body.append(container)
    mainContent.prepend(container);
    container.className = "col-lg-12";
    this.detectionContainer = document.getElementById("detectionResult");
    var btnCheckIn = document.getElementById("btnCheckIn");
    var btnCheckOut = document.getElementById("btnCheckOut");
    btnCheckIn.disabled = true;
    btnCheckOut.disabled = true;
    this.targetPicHost = "https://beamingschool.com/face2";
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    this.person = urlParams.get("PersonID");
    this.school = urlParams.get("schoolid");
    if (this.school == "1035430236") {
      //MAHA
      this.targetCheckInAPI = "https://www.mahawit.xyz";
    } else if (this.school == "1033530923") {
      //PRANKOO
      this.targetCheckInAPI = "https://www.prangkuschool.online";
    }

    console.log(faceAPI);
    //console.log(this.jsonLanmark);

    Promise.all([
      faceAPI.nets.faceRecognitionNet.loadFromUri(
        "https://beamingschool.com/face2/models"
      ),
      faceAPI.nets.faceLandmark68Net.loadFromUri(
        "https://beamingschool.com/face2/models"
      ),
      faceAPI.nets.ssdMobilenetv1.loadFromUri(
        "https://beamingschool.com/face2/models"
      ),
    ]).then(this.start());
    ScriptFace.faceAPI(); // <---read data
    console.log(webcamAPI);
    let screenWidth = 320;
    webcamAPI.set({
      width: screenWidth,
      height: (screenWidth * 3) / 4,
      image_format: "jpeg",
      jpeg_quality: 90,
    });
    webcamAPI.attach("#my_camera");
  },
  methods: {
    login() {
      this.uploadingStat = 1;
      const form = document.getElementById("formLogin");
      const formData = new FormData(form);
      formData.append("txtAction", "get");
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == true) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            this.$router.push({ path: "../dashboard" });
          }
        })
        .finally(() => {});
    },
    start() {
      console.log("test async");
      const labeledFaceDescriptors = this.loadLabeledImages();
      const faceMatcher = new faceAPI.FaceMatcher(labeledFaceDescriptors, 0.6);
    },
    loadLabeledImages() {
      console.log("loading label");
      let labels = [
        "Black Widow",
        "Captain America",
        "Captain Marvel",
        "Hawkeye",
        "Jim Rhodes",
        "Thor",
        "Tony Stark",
        "Jettnipith",
        "Channarong",
        "Auy",
        "Aing",
        "Nanfaa",
      ];

      labels = [this.person, "Black Widow"];
      //console.log(labels);

      // Make a request for a user with a given ID
      this.axios
        .get(
          "https://beamingschool.com/face2/labeled_images/" +
            this.person +
            "/1.jpg"
        )
        .then(function (response) {
          // handle success
          //console.log(response);
          if (response.data.search("DOCTYPE") > 0) {
            alert("ไม่พบฐานข้อมูลใบหน้า");
            history.go(-1);
          }
        })
        .then(function () {
          // always executed
        });

      return Promise.all(
        labels.map(async (label) => {
          const descriptions = [];
          for (let i = 1; i <= 2; i++) {
            //const img = await faceapi.fetchImage(`https://raw.githubusercontent.com/WebDevSimplified/Face-Recognition-JavaScript/master/labeled_images/${label}/${i}.jpg`)

            const img = await faceAPI.fetchImage('https://beamingschool.com/face2/labeled_images/'+label+'/'+i+'.jpg');
            const detections = await faceAPI.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
            descriptions.push(detections.descriptor);
          }

          return new faceAPI.LabeledFaceDescriptors(label, descriptions);
        })
      );
    },
  },
};
</script>

<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
}

div {
  margin-top: 20px;
}

img {
  width: 100% !important;
}

video {
  width: 100% !important;
  height: auto !important;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.text-yellow80 {
  color: #f0be20;
}

.btn-yellow100 {
  background-color: #e26004;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.btn-yellow80 {
  background-color: #f0be20;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.bg-gray {
  background-color: #525252;
}
</style>
