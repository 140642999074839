//Mylib.js
let exports = {};



exports.testFunction = () => {
    console.log("test");
};

exports.faceAPI = () => {
    console.log("test FaceAPI");
};



export default exports;